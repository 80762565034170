import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPreloader } from './app.preload';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@admin/admin.module').then(m => m.AdminModule),
    pathMatch: 'prefix',
    data: { preload: true, },
  },
  {
    path: '**', redirectTo: '/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    preloadingStrategy: AppPreloader,
  })],
  exports: [RouterModule],
  providers: [AppPreloader]
})
export class AppRoutingModule { }
