import { AdminConfigurationService } from '@admin/admin-config.service';
import { EmployeesService } from '@admin/employees/employees.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '@context';
import { AdminConfigItem } from '@models/admin-config-item';
import { EmployeeItem } from '@models/employees-item';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent implements OnInit, OnDestroy {
  private adminConfigSubscription: Subscription | null = null;
  public avatarDropDownOpen: boolean = false;
  public employee$: BehaviorSubject<EmployeeItem | null> = this.employeesService.employee$;

  public get isDrawerOpen(): boolean {
    return this.context.state.isDrawerOpen
  }

  public get adminConfigurations(): AdminConfigItem {
    return this.adminConfig.configurations
  }

  constructor(
    private context: ContextService,
    private employeesService: EmployeesService,
    private adminConfig: AdminConfigurationService,
  ) { }

  ngOnInit(): void {
    this.adminConfigSubscription = this.adminConfig.state$.subscribe(config => { });
  }
  ngOnDestroy(): void {
    this.adminConfigSubscription?.unsubscribe();
  }

  public employeeLogout(): void {
    this.avatarDropDownOpen = false;
    this.employeesService.logout()
  }

  public toggleEmployee(): void {
    this.avatarDropDownOpen = !this.avatarDropDownOpen;
  }

  public closeEmployee(): void {
    this.avatarDropDownOpen = false;
  }

  public toggleDrawer(): void {
    this.context.setState({ isDrawerOpen: !this.context.state.isDrawerOpen });
    this.context.toggleShadow();
  }

  public closeDrawer(): void {
    this.context.setState({ isDrawerOpen: false });
    this.context.hideShadow();
  }
}
