<section class="list-search">
  <ng-container *ngTemplateOutlet="search || defaultSearch"></ng-container>
</section>
<section class="list-heading-row">
  <section class="row-cell">
    <label class="checkbox" (change)="handleSelectAll($event.target)">
      <input type="checkbox" [checked]="listSelected.length === listFiltered.length && listFiltered.length !== 0" />
    </label>
  </section>
  <section class="row-cell">
    <span>Име</span>
  </section>
</section>
<section class="list-body" data-borders>
  <section *ngFor="let item of listFiltered | paginateArray : activePage : limit" class="row pointer" (click)="handleSelect(item)">
    <section class="row-cell">
      <label class="checkbox">
        <input type="checkbox" [checked]="isSelected(item)" />
      </label>
    </section>
    <section class="row-cell">
      <span>{{ item.text }}</span>
    </section>
  </section>
</section>
<app-pagination (onChange)="handleOnPageChange($event)" [page]="activePage" [step]="limit" [list]="listFiltered.length || 0"> </app-pagination>

<ng-template #defaultSearch>
  <input class="outlined" name="search-products" type="search" (change)="handleSearch($event)" placeholder="Търси ({{ list.length }})" />
</ng-template>
