import { Pipe, PipeTransform } from '@angular/core';

const ORDERS_STATUSES_DICTIONARY: { [key: string]: string } = {
  'pending': 'В Очакване',
  'in-progress': 'В Процес',
  'on-the-road': 'На път',
  'done': 'Приключена',
  'canceled': 'Отказана',
}

@Pipe({
  name: 'translateOrderStatus'
})
export class TranslateOrderStatusPipe implements PipeTransform {
  transform(value: string): unknown {
    return (value in ORDERS_STATUSES_DICTIONARY) ?
      ORDERS_STATUSES_DICTIONARY[value]
      :
      value
  }
}
