import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[click-away]'
})
export class ClickAwayDirective {
  constructor(private elementRef: ElementRef) { }

  @Output()
  public clickAway = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) return

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) this.clickAway.emit(event)
  }
}