import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { SelectItem } from '@models/commons-models';

@Component({
  selector: 'app-add-remove-list',
  templateUrl: './add-remove-list.component.html',
  styleUrls: ['./add-remove-list.component.scss']
})
export class AddRemoveListComponent implements OnInit {
  public originList: SelectItem[] = [];
  public defaultList: SelectItem[] = [];
  public originListSelected: SelectItem[] = [];
  public defaultListSelected: SelectItem[] = [];
  @ContentChild('originSearch') originSearch: TemplateRef<any> | undefined;
  @ContentChild('defaultSearch') defaultSearch: TemplateRef<any> | undefined;
  @Output() save = new EventEmitter<SelectItem[]>();
  @Input() set data(value: SelectItem[] | null) {
    if (!value) {
      this.originList = [];
      return
    }
    this.originList = value;
  }
  @Input() set default(value: SelectItem[] | null) {
    if (!value) {
      this.defaultList = [];
      return
    }
    this.defaultList = value;
  }
  constructor() { }

  ngOnInit(): void { }

  public handleOnSelectFromOriginList(event: SelectItem[]) {
    this.originListSelected = event;
  }
  public handleOnSelectFromDefaultList(event: SelectItem[]) {
    this.defaultListSelected = event;
  }
  public handleAddToDefaultList() {
    this.defaultList = this.defaultList
      .concat(this.originListSelected)
      .filter((item, index, list) => {
        return list.findIndex(x => x.key === item.key) === index;
      });
    this.originListSelected = [];
  }
  public handleRemoveFromDefaultList() {
    this.defaultList = this.defaultList.filter(x => {
      const marked = this.defaultListSelected.find(item => item.key === x.key)
      return !Boolean(marked)
    });
    this.defaultListSelected = [];
  }
  public handleClickSaveButton() {
    this.save.emit(this.defaultList);
  }
}
