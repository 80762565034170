<section *ngIf="item" class="card-icons">
  <span
    *ngIf="
      (item.hasOwnProperty('featured') && item.featured) ||
      (item.hasOwnProperty('isFeatured') && item.isFeatured)
    "
    class="icons-star accent"
    >★</span
  >
  <span
    *ngIf="
      (item.hasOwnProperty('featured') && !item.featured) ||
      (item.hasOwnProperty('isFeatured') && !item.isFeatured)
    "
    class="icons-star"
    >☆</span
  >
  <span *ngIf="item.enabled" class="icons-status">✅</span>
  <span *ngIf="!item.enabled" class="icons-status">❌</span>
  <span
    *ngIf="
      (item.hasOwnProperty('promotion') && !!item.promotion) ||
      (item.hasOwnProperty('daily_offer') && !!item.daily_offer)
    "
    class="warn"
    >%</span
  >
  <app-icon
    *ngIf="
      (item.hasOwnProperty('image') && item.image.length) ||
      item.hasOwnProperty('imageToken')
    "
    src="/assets/icons/image.svg"
    fontSize="1em"
    color="var(--text-color)"
  ></app-icon>

  <ng-container
    *ngIf="item.hasOwnProperty('productsIds') && item.productsIds.length"
  >
    <p class="productsCount">
      <span>( {{ item.productsIds.length }}</span>
      <app-icon
        src="/assets/icons/products-icon.svg"
        fontSize="1em"
        color="var(--text-color)"
      ></app-icon>
      )
    </p>
  </ng-container>

  <ng-container
    *ngIf="item.hasOwnProperty('ingredientsIds') && item.ingredientsIds.length"
  >
    <p class="productsCount">
      <span>( {{ item.ingredientsIds.length }}</span>
      <app-icon
        src="/assets/icons/ingredients.svg"
        fontSize="1em"
        color="var(--text-color)"
      ></app-icon>
      )
    </p>
  </ng-container>
</section>
<p class="card-title">{{ text || item.name || item.title }}</p>
<section class="card-actions">
  <ng-content></ng-content>
</section>
