import { NgModule } from '@angular/core';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
import { NumberPipe } from './pipes/number.pipe';
import { TimeViewPipe } from './pipes/time-view.pipe';
import { TranslateEmployeePipe } from './pipes/translate-employee.pipe';
import { TranslateOrderOriginPipe } from './pipes/translate-order-origin.pipe';
import { TranslateOrderPaymentPipe } from './pipes/translate-order-payment.pipe';
import { TranslateOrderStatusPipe } from './pipes/translate-order-status.pipe';
import { TranslateRolePipe } from './pipes/translate-roles';
@NgModule({
  declarations: [
    TranslateOrderOriginPipe,
    TranslateOrderStatusPipe,
    TranslateOrderPaymentPipe,
    TimeViewPipe,
    TranslateRolePipe,
    TranslateEmployeePipe,
    NumberPipe,
  ],
  imports: [
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule,
  ],
  exports: [
    ComponentsModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateOrderOriginPipe,
    TranslateOrderStatusPipe,
    TranslateOrderPaymentPipe,
    TranslateEmployeePipe,
    TimeViewPipe,
    TranslateRolePipe,
    NumberPipe,
  ],
})
export class SharedModule { }
