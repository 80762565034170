import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
            <main class="app">
              <app-admin-nav class="nav"></app-admin-nav>
              <router-outlet></router-outlet>
            </main>
            <app-snack-bar></app-snack-bar>
            `,
})
export class AppComponent {
  constructor() { }
}