import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CategoryItem } from '@models/CategoryProduct.model';
import { ProductItem } from '@models/Product.model';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
@Injectable()

export class RepositoryService {
  private _loading: boolean = false;

  public set isLoading(value: boolean) {
    this._loading = value;
  }

  public get isLoading(): boolean {
    return this._loading
  }

  constructor(
    private db: AngularFirestore,
  ) { }

  public categoryDictionary(category: CategoryItem): Observable<ProductItem[]> {
    if (category.id === 1) return this.getRolls();
    if (category.id === 2) return this.getSushi();
    if (category.id === 3) return this.getSnacks();
    if (category.id === 4) return this.getSets();
    if (category.id === 5) return this.getDeserts();
    if (category.id === 6) return this.getDrinks();
    if (category.id === 7) return this.getSauces();
    if (category.id === 8) return this.getService();

    return of()
  }
  private sortProducts(array: ProductItem[]): ProductItem[] {
    return array.sort((a, b) => {
      const a1 = a.daily_offer ? 1 : 0
      const b1 = b.daily_offer ? 1 : 0
      return b1 - a1 || a.title.localeCompare(b.title)
    })
  }
  private getProductsByCategory(categoryId: number, categoryName: string): Observable<ProductItem[]> {
    return this.db.collection<ProductItem>('products', ref => ref.where('categoryId', '==', categoryId))
      .valueChanges({ idField: 'id' })
      .pipe(shareReplay())
  }

  //CATEGORY PRODUCTS
  public getService(): Observable<ProductItem[]> {
    return this.getProductsByCategory(8, 'getService')
  }
  public getRolls(): Observable<ProductItem[]> {
    return this.getProductsByCategory(1, 'getRolls')
  }
  public getSushi(): Observable<ProductItem[]> {
    return this.getProductsByCategory(2, 'getSushi')
  }
  public getSnacks(): Observable<ProductItem[]> {
    return this.getProductsByCategory(3, 'getSnacks')
  }
  public getSets(): Observable<ProductItem[]> {
    return this.getProductsByCategory(4, 'getSets')
  }
  public getDeserts(): Observable<ProductItem[]> {
    return this.getProductsByCategory(5, 'getDeserts')
  }
  public getDrinks(): Observable<ProductItem[]> {
    return this.getProductsByCategory(6, 'getDrinks')
  }
  public getSauces(): Observable<ProductItem[]> {
    return this.getProductsByCategory(7, 'getSauces')
  }
}
