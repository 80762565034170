import { FormControl, FormGroup } from '@angular/forms';

type textTransform = 'lowercase' | 'uppercase' | 'capitalize';

export const groupBy = (array: any[], callback?: Function): any => {
  return array.reduce((acc: any, item: any) => {
    const dataKey = callback ? callback(item).toString() : item.toString();
    if (!dataKey) return acc;
    acc.hasOwnProperty(dataKey) ?
      acc[dataKey] = [...acc[dataKey], item]
      :
      acc[dataKey] = [item];
    return acc
  }, {})
}

export const uniqueArray = (arr: Array<any>): Array<any> => {
  return [...new Set(arr)]
}

export const paginateArray = (arr: Array<any>, page = 1, limit = 5) => {
  if (page <= 1) return arr.slice(0, limit);
  return arr.slice((page - 1) * limit, ((page - 1) * limit) + limit);
}

export const validateAllFormFields = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
      control.markAsDirty({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
}

export const generateKeyWord = (value: string | number | null | undefined): string[] => {
  if (!value || !value.toString().length) return []
  const parsedValue = formatForUrl(value.toString(), false)
    .replace(/-/gi, ' ')
    .split(' ')
    .reduce((acc: string[], word: string) => {
      if (word.length <= 2) return acc
      acc.push(word);
      const parsedWord = word.split('').slice(0, 6).join('');
      for (let i = 3; i < parsedWord.length; i++) {
        acc.push(parsedWord.substring(0, i));
      }
      return acc
    }, [])
  return uniqueArray(parsedValue)
}

export const dateFromUTCString = (value: string): Date => {
  if (!value.length) return new Date()

  const day = new Date();
  const [year, month, date] = value.split('-');
  day.setUTCDate(Number(date));
  day.setUTCMonth(Number(month));
  day.setUTCFullYear(Number(year));
  return day
}

export const numberFallback = (value: any, fallback: any[]): number => {
  const isInvalidNumber = (n: any): boolean => {
    return (isNaN(Number(n)) || (typeof n === 'undefined') || n === null || n === false)
  }
  let invalidStatus = isInvalidNumber(value);
  if (!invalidStatus) return Number(value);

  const fallbackData = fallback.slice();
  while (invalidStatus && !!fallbackData.length) {
    value = fallbackData.shift();
    invalidStatus = isInvalidNumber(value);
  }
  if (!fallbackData.length && invalidStatus) return 0
  return Number(value);
}

export const timeTemplate = (date: number): string => {
  const time = new Date(date);
  time.setUTCHours(0);
  time.setUTCMinutes(0);
  time.setUTCSeconds(0);
  time.setUTCMilliseconds(0);
  return time.toUTCString();
}

export const compareTwoStrings = (first: string, second: string): number => {
  first = first.replace(/\s+/g, '')
  second = second.replace(/\s+/g, '')

  if (first === second) return 1;
  if (first.length < 2 || second.length < 2) return 0;

  let firstBigrams = new Map();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram)
      ? firstBigrams.get(bigram) + 1
      : 1;

    firstBigrams.set(bigram, count);
  };

  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram)
      ? firstBigrams.get(bigram)
      : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  return (2.0 * intersectionSize) / (first.length + second.length - 2);
}

export const sleep = async (duration: number = 2000): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, duration);
  })
}

export const chunkArray = (arr: Array<any>, chunkSize: number): Array<any> => {
  if (chunkSize <= 0) throw "Invalid chunk size";
  const temp = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize) {
    temp.push(arr.slice(i, i + chunkSize));
  }
  return temp;
}

export const generateCSVFile = (headers: Array<string>, data: any[][]): string => {
  if (!data || !data.length || !headers || !headers.length) return ''

  return data.reduce((acc, item) => {
    acc.push(item.join(', '));
    return acc
  }, [headers.join(', ')]).join('\n');
}

export const createBlobElement = (array: any, type: string) => {
  return URL.createObjectURL(new Blob([array], { type: type }))
};

//PARSERS
export const normalizeValue = (value: string | number): string => {
  return value.toString().toLowerCase().replace(/\s/g, '');
}
export const parseToBoolean = (value: string | boolean | undefined): boolean => {
  if (typeof value === 'undefined') return false
  if (typeof value === 'string') return (value === 'true')
  if (typeof value === 'number') return Boolean(value)

  return value
}
export const parseNumber = (value: any, msg = `Invalid value`) => {
  const parsedValue = (value || 0).toString().replace(/лв/ig, '').replace(/\s/ig, '');
  if (isNaN(Number(parsedValue))) throw new Error(msg)
  return Number(parsedValue) < 0 ? 0 : Number(parsedValue)
}
export const formatForUrl = (text: string, parse: boolean = true): string => {
  const url = text
    .toString()
    .trim()
    .replace(/\s/g, '-')
    .replace(/[^a-z0-9а-я\/]/gi, '-')
    .replace(/-+/g, '-');

  if (parse) return transliterate(url.toLowerCase())
  return url.toLowerCase()
}
export const formatText = (text: string, delimiter: string, join: string, type: textTransform = 'lowercase') => {
  let words = text.toString().split(delimiter);
  if (type === 'lowercase') words = words.map(part => part.toLowerCase());
  if (type === 'capitalize') words = words.map(part => `${part.toUpperCase()[0]}${part.toLowerCase().substring(1)}`);
  if (type === 'uppercase') words = words.map(part => part.toUpperCase());
  return words.join(join);
}
export const transliterate = (word: string): string => {
  const a: { [key: string]: string } = {
    "а": "a",
    "б": "b",
    "в": "v",
    "г": "g",
    "д": "d",
    "э": "e",
    "е": "e",
    "ж": "j",
    "з": "z",
    "и": "i",
    "й": "i",
    "ѝ": "i",
    "к": "k",
    "л": "l",
    "м": "m",
    "н": "n",
    "о": "o",
    "п": "p",
    "р": "r",
    "с": "s",
    "т": "t",
    "у": "u",
    "ф": "f",
    "х": "h",
    "ц": "ts",
    "ч": "ch",
    "ш": "sh",
    "щ": "sch",
    "ъ": "y",
    "ь": "y",
    "ю": "u",
    "я": "ya",
  };
  return word
    .split("")
    .map((char) => a[char.toLowerCase()] || char.toLowerCase())
    .join("");
}

