import { Pipe, PipeTransform } from '@angular/core';

const ORDERS_STATUSES_DICTIONARY: { [key: string]: string } = {
  'driver': 'Доставчик',
  'marker': 'Маркиращ',
  'admin': 'Админ',
}

@Pipe({
  name: 'translateRole'
})
export class TranslateRolePipe implements PipeTransform {
  transform(value: string): unknown {
    return (value in ORDERS_STATUSES_DICTIONARY) ?
      ORDERS_STATUSES_DICTIONARY[value]
      :
      value
  }
}
