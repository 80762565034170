import { ChangeDetectionStrategy, Component, DoCheck, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { AccordionService } from '../accordion.service';

@Component({
  selector: 'accordion-head',
  templateUrl: './accordion-head.component.html',
  styleUrls: ['./accordion-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccordionHeadComponent implements DoCheck {
  @HostBinding('attr.data-expanded') expanded: boolean = false;
  @HostListener('click', ['$event']) onClick(ev: Event) {
    this.accService.toggleExpanded();
  }

  constructor(public accService: AccordionService) { }

  ngDoCheck(): void {
    this.expanded = this.accService.open
  }
}
