import { AdminService } from '@admin/admin.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ContextService } from '@context';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {

  constructor(
    private adminService: AdminService,
    private context: ContextService,
    private router: Router,
  ) {
    this.router.events.subscribe((val) => {
      if (!val) return
      this.closeDrawer(null);
    });
  }

  ngOnInit(): void {
  }

  public closeDrawer(event: any): void {
    if (!event) return this.context.setState({ isDrawerOpen: false });
    const rootNode = event.path.find((el: HTMLElement) => {
      return el.nodeName === 'APP-ADMIN-NAV'
    });
    if (rootNode) return
    this.context.setState({ isDrawerOpen: false });
  }

  public logout(): void {
    this.adminService.logout();
  }
}
