import { Pipe, PipeTransform } from '@angular/core';
import { paginateArray } from '@utilities';

@Pipe({
  name: 'paginateArray'
})
export class PaginateArrayPipe implements PipeTransform {
  transform(arr: any[], activePage: number, step: number): any[] {
    return paginateArray(arr, activePage, step)
  }
}
