import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeView'
})
export class TimeViewPipe implements PipeTransform {
  isInvalidNumber = (n: any): boolean => {
    return (isNaN(Number(n)) || (typeof n === 'undefined') || n === null || n === false)
  }

  transform(value: any, fallback: any[]): unknown {
    let invalidStatus = this.isInvalidNumber(value);
    if (!invalidStatus) return value.toString().padStart(2, '0');

    const fallbackData = fallback.slice();
    while (invalidStatus && !!fallbackData.length) {
      value = fallbackData.shift();
      invalidStatus = this.isInvalidNumber(value);
    }
    if (!fallbackData.length) return '--'
    return value.toString().padStart(2, '0');
  }
}
