import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ContextService } from '@context';
import { EmployeeItem, RoleTypes } from '@models/employees-item';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable()

export class EmployeesService implements OnDestroy {
  private subscription: Subscription | null = null;
  private employeesList: EmployeeItem[] | null = null;
  private readonly employeesRef: AngularFirestoreCollection<EmployeeItem> = this.db.collection<EmployeeItem>('employees');
  public readonly employee$: BehaviorSubject<EmployeeItem | null> = new BehaviorSubject<EmployeeItem | null>(this.initialEmployee);
  public isLoading: boolean = false;

  private get initialEmployee() {
    if (typeof window === 'undefined') return null

    return null
  }

  //STATE
  public get currentEmployee(): EmployeeItem | null {
    return this.employee$.getValue();
  }
  private setState = (newState: EmployeeItem | null): void => {
    this.employee$.next(newState);
  }

  constructor(
    private db: AngularFirestore,
    private context: ContextService,
    private router: Router,
  ) {
    if (this.context.state.isServer) return

    this.subscription = this.employeesRef
      .valueChanges({ idField: 'id' })
      .subscribe((data: EmployeeItem[]) => {
        this.employeesList = data;
        const currentEmployee = JSON.parse(window.localStorage.getItem('employee') || 'null');
        if (currentEmployee) {
          const employee = data.find(e => e.id === currentEmployee.id);
          window.localStorage.setItem('employee', JSON.stringify(employee));
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  //ROLES
  public roles(): RoleTypes[] {
    return ['driver', 'marker', 'admin']
  }

  //EMPLOYEES
  public async add(user: EmployeeItem): Promise<void> {
    try {
      this.isLoading = true;
      const isExist = !!this.employeesList?.find(u => {
        u.username === user.username || u.id === user.id || u.email === user.email
      });
      if (isExist) {
        this.context.openFailureSnackBar('Toзи служител вече същестлува.');
        this.isLoading = false;
        return
      }
      const response = await this.employeesRef.add(user);
      await this.employeesRef.doc(response.id).update({
        ...user,
        id: response.id,
        dateAdded: new Date().getTime(),
        lastModified: 0,
        lastLogin: 0,
      })
      this.context.openSuccessSnackBar('Служителя е създаден.');
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.isLoading = false;
    }
  }
  public async update(user: EmployeeItem): Promise<void> {
    try {
      this.isLoading = true;
      const isExist = !!this.employeesList?.find(u => {
        return u.username === user.username || u.id === user.id || u.email === user.email
      });
      if (!isExist) {
        this.context.openFailureSnackBar('Toзи служител не е намерен.');
        this.isLoading = false;
        return
      }
      await this.employeesRef.doc(user.id.toString()).update({
        ...user,
        lastModified: new Date().getTime(),
      })
      this.context.openSuccessSnackBar('Служителя е променен.');
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.isLoading = false;
    }
  }
  public async remove(user: EmployeeItem): Promise<void> {
    try {
      this.isLoading = true;
      const isExist = !!this.employeesList?.find(u => {
        return u.username === user.username || u.id === user.id || u.email === user.email
      });
      if (!isExist) {
        this.context.openFailureSnackBar('Toзи служител не е намерен.');
        this.isLoading = false;
        return
      }
      await this.employeesRef.doc(user.id.toString()).delete()
      this.context.openSuccessSnackBar('Служителя е премахнат.');
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.isLoading = false;
    }
  }
  public list(): Observable<EmployeeItem[]> {
    return this.employeesRef.valueChanges({ idField: 'id' })
  }
  public get(id: string): EmployeeItem | null {
    const { user } = this.context.state;
    return this.employeesList?.find(e => e.id === id) || null
  }


  //STATE
  public async login(password: string): Promise<EmployeeItem | null> {
    try {
      this.isLoading = true;
      const employee = this.employeesList?.find(u => {
        return u.password === password
      });

      if (!employee) {
        this.context.openFailureSnackBar('Toзи служител не е намерен.');
        this.isLoading = false;
        return null
      }

      await this.employeesRef.doc(employee.id.toString()).update({
        ...employee,
        lastLogin: new Date().getTime(),
      });
      window.localStorage.setItem('employee', JSON.stringify(employee));
      // this.router.navigate(['/']);
      this.isLoading = false;
      this.context.openFailureSnackBar('Успешен вход.');
      this.setState(employee);
      return employee
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.isLoading = false;
      return null
    }
  }
  public async logout(): Promise<void> {
    try {
      this.isLoading = true;
      this.setState(null);
      window.localStorage.removeItem('employee');
      this.context.openSuccessSnackBar('Успешен изход.');
      // this.router.navigate(['/employees/login']);
      this.isLoading = false;
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.isLoading = false;
    }
  }
}

