import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Number'
})
export class NumberPipe implements PipeTransform {
  transform(value: string): number {
    const valueParsed = Number(value);
    return isNaN(valueParsed) ? 0 : valueParsed
  }
}
