import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
@Injectable()

export class ScreenService implements OnDestroy {
  private subscription$: Subject<any> = new Subject();
  public screenWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
  public mediaBreakpoint$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor() {
    if (typeof window === 'undefined') return

    this.setScreenWidth(window.innerWidth);
    this.setMediaBreakpoint(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(1000),
        takeUntil(this.subscription$)
      ).subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        this.setMediaBreakpoint(evt.target.innerWidth);
      });
  }

  ngOnDestroy() {
    this.subscription$.next();
    this.subscription$.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth$.next(width);
  }

  private setMediaBreakpoint(width: number): void {
    if (width < 599) {
      this.mediaBreakpoint$.next('xs');

    } else if (width >= 600 && width <= 959) {
      this.mediaBreakpoint$.next('sm');
    } else if (width >= 960 && width <= 1279) {
      this.mediaBreakpoint$.next('md');
    } else if (width >= 1280 && width <= 1919) {
      this.mediaBreakpoint$.next('lg');
    } else if (width >= 1920) {
      this.mediaBreakpoint$.next('xl');
    }
  }
}