<article *ngIf="cartItem" class='product-mini-card' [attr.data-type]="type" [attr.data-selected]="!!count">
  <h2 class="title title-3-semibold">{{showCategory ? cartItem.item.category?.name + ' - ' : ''}}
    {{cartItem.item.title}}
  </h2>
  <img src='' width="0" height="0" />

  <picture class="card-img">
    <source *ngIf="cartItem.item.imageToken" [attr.srcset]="getProductImage.webp['250']" type="image/webp" />
    <source *ngIf="cartItem.item.imageToken" [attr.srcset]="getProductImage.png['250']" type="image/png" />
    <img src="/assets/product/product-card-default.png" height="0" width="0" loading="lazy"
      alt="{{cartItem.item.title}} product card" type='image/png' />
  </picture>

  <div class='action'>
    <button class="mat-display-3" (click)="handleRemoveItem(cartItem.item)">
      <span>-</span>
    </button>
    <span class='count mat-title'>{{count.toString().padStart(2, "0")}}</span>
    <button class="mat-display-3" (click)="handleAddItem(cartItem.item)">
      <span>+</span>
    </button>
    <span class="price mat-title">{{price}} лв.</span>
  </div>

  <!-- <span class='remove mat-display-1' (click)="handleTotalRemove(cartItem.item, cartItem.count)">✕</span> -->
</article>