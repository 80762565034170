import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { SelectItem } from '@models/commons-models';
import { normalizeValue } from '@utilities';

@Component({
  selector: 'app-table-add-remove',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableAddRemoveComponent implements OnInit {
  public list: SelectItem[] = [];
  @Input() listSelected: SelectItem[] = [];
  @Input() listFiltered: SelectItem[] = [];
  public limit: number = 100;
  public activePage: number = 1;

  @Input() set data(value: SelectItem[]) {
    this.list = value;
    this.listFiltered = value;
  }
  @Output() select = new EventEmitter<SelectItem[]>();
  @ContentChild('search') search: TemplateRef<any> | undefined;

  constructor() { }

  ngOnInit(): void { }

  public handleSearch(event: any): void {
    const searchValue = event.target.value || '';

    const wordsNormalized = searchValue.trim().split(' ').map(normalizeValue);
    this.listFiltered = this.list.filter(data => {
      const { key, text, value } = data;

      const matchedWords = wordsNormalized.reduce((acc: number, word: string) => {
        const matchKey = normalizeValue(key).includes(word);
        const matchText = normalizeValue(text).includes(word);
        const matchValue = normalizeValue(value).includes(word)
        const isMatch = matchKey || matchText || matchValue;

        return isMatch ? acc + 1 : acc
      }, 0);

      return matchedWords === wordsNormalized.length
    })
  }
  public isSelected(item: any): boolean {
    return !!(this.listSelected || []).find(x => x.key === item.key)
  }
  public handleSelect(item: any): void {
    !this.isSelected(item) ?
      this.listSelected.push(item)
      :
      this.listSelected.splice(this.listSelected.findIndex(x => x.key === item.key), 1);
    this.select.emit(this.listSelected);
  }
  public handleSelectAll({ checked }: any): void {
    checked ?
      this.list.forEach(x => this.listSelected.push(x))
      :
      this.listSelected.splice(0, this.listSelected.length)

    this.select.emit(this.listSelected);
  }
  public handleOnPageChange(page: any) {
    this.activePage = page;
  }
}
