import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { ScreenService } from '@core/services/screen.service';
import { CartItem } from '@models/Cart.model';
import { ingredientsItem } from '@models/Ingredients.model';
import { ProductItem } from '@models/Product.model';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';

@Injectable()

export class ContextService {
  private readonly initialState: any = {};
  private cartItems: CartItem[] = [];
  public readonly state$: BehaviorSubject<any> = new BehaviorSubject<any>(this.initialState);
  public readonly cartItemsList$: BehaviorSubject<CartItem[]> = new BehaviorSubject<CartItem[]>([]);

  constructor(
    private db: AngularFirestore,
    private screen: ScreenService,
    private transferState: TransferState,
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) protected request: Request,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.setState({
        ...this.initialState,
        ...this.transferState.get(makeStateKey<Object>('state'), this.initialState),
        isBrowser: true,
        isServer: false,
        isDrawerOpen: false,
      });
      this.screen.mediaBreakpoint$.subscribe(screenSize => {
        const SCREEN_DICTIONARY: any = {
          xl: { isXL: true, isLG: false, isMD: false, isSM: false, isXS: false },
          lg: { isXL: true, isLG: true, isMD: false, isSM: false, isXS: false },
          md: { isXL: true, isLG: true, isMD: true, isSM: false, isXS: false },
          sm: { isXL: true, isLG: true, isMD: true, isSM: true, isXS: false },
          xs: { isXL: true, isLG: true, isMD: true, isSM: true, isXS: true },
        };
        this.setState({ ...SCREEN_DICTIONARY[screenSize] });
      });
    }
    if (isPlatformServer(this.platformId)) {
      this.deviceService.setDeviceInfo(request.headers['user-agent']);
      this.setState({
        isXL: this.deviceService.isDesktop(),
        isLG: this.deviceService.isDesktop(),
        isMD: this.deviceService.isTablet(),
        isSM: this.deviceService.isTablet(),
        isXS: this.deviceService.isMobile(),
        isBrowser: false,
        isServer: true,
      });
    }
  }

  public ingredientsList$(): Observable<ingredientsItem[]> {
    if (!this.state.ingredientsList) {
      return this.db.collection<ingredientsItem>('ingredients')
        .valueChanges({ idField: 'id' })
        .pipe(
          take(2),
          tap(ingredients => this.setState({ ingredientsList: ingredients })),
          shareReplay(),
        )
    } else {
      return of(this.state.ingredientsList)
    }
  }

  //SHADOW-CONTROL
  public showShadow(callback?: () => void): void {
    this.setState({ shadow: true });
    if (!callback) return undefined
    callback();
  }
  public hideShadow(callback?: () => void): void {
    this.setState({ shadow: false });
    if (!callback) return undefined
    callback();
  }
  public toggleShadow(callback?: () => void): void {
    this.setState({ shadow: false });
    if (!callback) return undefined
    callback();
  }

  //CART FUNCTIONALITY
  public addToCart(data: ProductItem, count: number = 1): void {
    const product = this.cartItems.find(x => x.item.id === data.id) || false;

    if (!product) {
      this.cartItems.push({ item: data, count });
      this.cartItems = this.cartItems.slice().sort((a, b) => Number(a.item.categoryId) - Number(b.item.categoryId))
    } else {
      product.count = product.count + count;
    }
    this.cartItemsList$.next(this.cartItems);
  }
  public removeFromCart(data: ProductItem, count: number = 1): void {
    const product = this.cartItems.find(x => x.item.id === data.id) || false;

    if (product) {
      if (product.count >= count) {
        product.count = product.count - count;
      } else {
        // this.cartItems = this.cartItems.filter(x => x.item.id !== data.id);
        if (this.cartItems.length === 0) {
          this.setState({ isEmptyCart: true, isOpenCart: false });
        }
      }
    }

    this.cartItemsList$.next(this.cartItems);
  }
  public clearCart(): void {
    this.setState({ isEmpty: true, isOpenCart: false });
    this.cartItems = [];
    this.cartItemsList$.next(this.cartItems);
  }
  public countCart(): number {
    return this.cartItems.reduce((acc, item) => {
      return acc + item.count
    }, 0);
  }


  //SNACKBAR
  public openSuccessSnackBar(message: string, duration: number = 2500) {
    this.setState({ snackBar: true, snackBarSuccessful: true, snackMessage: message });
    setTimeout(() => {
      this.setState({ snackBar: false, snackBarSuccessful: false, snackMessage: '' });
    }, duration);
  }
  public openFailureSnackBar(message: string, duration: number = 4000) {
    this.setState({ snackBar: true, snackBarWarn: true, snackMessage: message });
    setTimeout(() => {
      this.setState({ snackBar: false, snackBarWarn: false, snackMessage: '' });
    }, duration);
  }

  public formatForImageName(text: string): string {
    return text.toString().replace(/\s/g, '_').toLowerCase();
  }

  //STATE
  public get state(): any {
    return this.state$.getValue();
  }
  protected getState(): Observable<any> {
    return this.state$;
  }
  public setState(newState: Partial<any>): void {
    const updatedState = { ...this.state$.getValue(), ...newState };
    this.state$.next(updatedState);
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(makeStateKey<Object>('state'), updatedState);
    }
  }
}
