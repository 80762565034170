<section click-away class="nav paper" (clickAway)="closeDrawer($event)">
  <div class="top">
    <span class="logout primary paragraph-2-semibold" (click)="logout()"
      >Изход</span
    >
  </div>

  <article class="nav-item pointer" routerLink="/">
    <app-icon
      src="/assets/icons/home.svg"
      fontSize="1.1em"
      color="var(--text-color)"
    ></app-icon>
    <h3 class="h5-semibold pointer">Начало</h3>
  </article>

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/advertise.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Реклама</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/top-banner"
        >
          <span>Главен банер</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <!-- <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/person.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Служители</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/employees/login"
        >
          <span>Вход</span>
        </li>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/employees/list"
        >
          <span>Виж всички</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion> -->

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/categories.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Категории</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/categories"
        >
          <span>Виж всички</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/products.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Продукти</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/products"
        >
          <span>Виж всички</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/ingredients.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Съставки</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/ingredients"
        >
          <span>Виж всички</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/orders.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Поръчки - клиенти</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/order/list"
        >
          <span>Виж всички</span>
        </li>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/order/stats-client"
        >
          <span>Статистика</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/promo-codes.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Промо Кодове</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/promo-codes"
        >
          <span>Виж всички</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion>

  <!-- <app-accordion class="category-item">
    <accordion-head class="item-head">
      <article class="nav-item">
        <app-icon
          src="/assets/icons/cart-service.svg"
          fontSize="1.1em"
          color="var(--text-color)"
        ></app-icon>
        <h3 class="h5-semibold">Поръчки - служебни</h3>
      </article>
    </accordion-head>

    <accordion-body class="item-body">
      <ul>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/order/new"
        >
          <span>Създай</span>
        </li>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/order/list-service"
        >
          <span>Редактирай</span>
        </li>
        <li
          class="paragraph-2-semibold"
          routerLinkActive="active"
          routerLink="/order/stats"
        >
          <span>Статистика</span>
        </li>
      </ul>
    </accordion-body>
  </app-accordion> -->
</section>
