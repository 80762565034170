import { Injectable } from "@angular/core";
@Injectable()
export class AccordionService {
  public open: boolean = false;
  public icon: string | null = null;

  constructor() { }

  public toggleExpanded(open?: boolean): void {
    this.open = open ? open : !this.open;
  }
}