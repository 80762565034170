import { AdminMenuComponent } from '@admin/admin-drawer/admin-menu.component';
import { AdminNavComponent } from '@admin/admin-navigation/admin-nav.component';
import { AdminService } from '@admin/admin.service';
import { EmployeesService } from '@admin/employees/employees.service';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { RouterModule } from '@angular/router';
import { ContextService } from '@context';
import { NotificationService } from '@core/services/notification.service';
import { RepositoryService } from '@core/services/repository.service';
import { ScreenService } from '@core/services/screen.service';
import { SharedModule } from '@core/shared.module';
import { environment } from '@env';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminMenuComponent,
    AdminNavComponent,
  ],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule,
    SharedModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    AdminService,
    EmployeesService,
    RepositoryService,
    NotificationService,
    ScreenService,
    ContextService,
  ],
  exports: [SharedModule,],

})
export class AppModule { }
