import { ChangeDetectionStrategy, Component, DoCheck, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { AccordionService } from './accordion.service';
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  providers: [AccordionService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AccordionComponent implements OnInit, DoCheck {
  @Input() toggle: 'default' | 'focus' = 'default';
  @Input() open: boolean = false;
  @Input() icon: string | null = '/assets/icons/chevron-down.svg';
  @HostBinding('attr.data-expanded') expanded: boolean = false;
  @HostListener('document:click', ['$event']) handleClickAway(event: any): void {
    if (this.toggle === 'default') return

    const rect = this.elRef.nativeElement.getBoundingClientRect();
    const clickAway = !(
      (rect.top <= event.clientY && event.clientY <= rect.top + rect.height)
      &&
      (rect.left <= event.clientX && event.clientX <= rect.left + rect.width)
    );

    const accordionBody = this.elRef.nativeElement.querySelector('accordion-body').getBoundingClientRect();
    const clickAwayBody = !(
      (accordionBody.top <= event.clientY && event.clientY <= accordionBody.top + accordionBody.height)
      &&
      (accordionBody.left <= event.clientX && event.clientX <= accordionBody.left + accordionBody.width)
    );

    if (this.accService.open && clickAway && clickAwayBody) {
      this.accService.open = false;
    }
  }

  constructor(public accService: AccordionService, private elRef: ElementRef) { }

  ngOnInit(): void {
    this.accService.icon = this.icon;
    this.accService.open = this.open;
  }
  ngDoCheck(): void {
    this.expanded = this.accService.open
  }
}
