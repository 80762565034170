import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnChanges, AfterViewInit {
  @ViewChild("dialog") dialog: ElementRef | null = null;
  @Input() type: 'dialog' | 'modal' = 'modal';
  @Input() open: boolean = false;
  @Output() public onBackdropClick = new EventEmitter<MouseEvent>();
  @Output() public onOpen = new EventEmitter<MouseEvent>();
  @Output() public onCLose = new EventEmitter<MouseEvent>();
  @HostListener('document:keydown.escape') keyPressEscape(): void {
    if (this.dialog?.nativeElement.open) this.handleClose();
  }

  constructor() { }

  ngAfterViewInit(): void {
    if (!this.open) this.handleClose();
    if (this.open) this.handleOpen();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.open.currentValue) this.dialog?.nativeElement.close();
    if (changes.open.currentValue) this.handleOpen();
  }

  public handleOpen(): void {
    this.onOpen.emit();
    if (this.type === 'dialog') return this.dialog?.nativeElement.show();
    this.dialog?.nativeElement.showModal();
  }

  public handleClose(): void {
    this.onCLose.emit();
    this.dialog?.nativeElement.close();
  }

  public handleClick(event: any): void {
    const rect = this.dialog?.nativeElement.getBoundingClientRect();
    const clickAway = !Boolean(rect.top <= event.clientY && event.clientY <= rect.top + rect.height
      && rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (clickAway && this.dialog?.nativeElement.open) {
      this.onBackdropClick.emit();
      this.handleClose();
    }
  }
}
