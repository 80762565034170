import { NgModule } from '@angular/core';
import { ClickAwayDirective } from './click-away.directive';
import { DragDropDirective } from './drag-n-drop.directive';

const directives: any = [
  DragDropDirective, ClickAwayDirective
];
@NgModule({
  declarations: directives,
  imports: [],
  exports: directives,
})
export class DirectivesModule { }

