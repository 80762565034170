import { EmployeesService } from '@admin/employees/employees.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateEmployee'
})
export class TranslateEmployeePipe implements PipeTransform {

  constructor(
    private employeeService: EmployeesService
  ) { }

  transform(id: string): string {
    const employee = this.employeeService.get(id);
    return employee?.displayName || employee?.username || 'Admin'
  }
}
