import { Component } from '@angular/core';
import { ContextService } from '@context';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})

export class SnackBarComponent {

  public get isOpen(): boolean {
    return this.context.state.snackBar;
  }
  public get snackMessage(): boolean {
    return this.context.state.snackMessage;
  }
  public get snackBarWarn(): boolean {
    return this.context.state.snackBarWarn
  }
  public get snackBarSuccessful(): boolean {
    return this.context.state.snackBarSuccessful
  }

  constructor(
    private context: ContextService
  ) { }

  public closeSnackBar(): void {
    this.context.setState({ snackBar: false, snackMessage: '', });
  }
}
