import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class UtilitiesConfigService {

  constructor() { }

  public dateFromUTCString(value: string): Date {
    if (!value.length) return new Date()

    const day = new Date();
    const [year, month, date] = value.split('-');
    day.setUTCDate(Number(date));
    day.setUTCMonth(Number(month));
    day.setUTCFullYear(Number(year));
    return day
  }

  public numberFallback(value: any, fallback: any[]): number {
    const isInvalidNumber = (n: any): boolean => {
      return (isNaN(Number(n)) || (typeof n === 'undefined') || n === null || n === false)
    }
    let invalidStatus = isInvalidNumber(value);
    if (!invalidStatus) return Number(value);

    const fallbackData = fallback.slice();
    while (invalidStatus && !!fallbackData.length) {
      value = fallbackData.shift();
      invalidStatus = isInvalidNumber(value);
    }
    if (!fallbackData.length && invalidStatus) return 0
    return Number(value);
  }

  public timeTemplate(date: number): string {
    const time = new Date(date);
    time.setUTCHours(0);
    time.setUTCMinutes(0);
    time.setUTCSeconds(0);
    time.setUTCMilliseconds(0);
    return time.toUTCString();
  }
}