<hr *ngIf="isPrepared" class="confirm-shadow" (click)="cancel()" />

<article #confirm *ngIf="isPrepared" class="confirm-root paper">
    <p class="root-text title-1-semibold">{{question}}</p>
    <article class="root-actions">
        <button class="contained-button" [attr.data-color]="color" [disabled]="loading" (click)="action()">
            {{label}}
        </button>
        <button class="contained-button" data-color="basic" (click)="cancel()">
            Откажи
        </button>
    </article>
</article>

<article *ngIf="!isPrepared" (click)="prepare()" class="confirm-child">
    <ng-content></ng-content>
</article>