import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() onChange = new EventEmitter<number>();
  @Input() list: number = 0;
  @Input() page: number = 1;
  @Input() step: number = 5;
  @Input() maxItems: number = 4;
  @Input() showPageDropdown: boolean = true;
  @Input() showNavigation: boolean = true;
  @Input() showLabel: boolean = true;

  public pages: number = 0;
  public pagesOptions: number[] = [];

  private get pagesArray(): number[] {
    return this.pagesOptions = [...new Array(this.pages)].map((x, i) => (i + 1));
  }

  constructor() { }

  ngOnInit(): void {
    this.pages = Math.ceil(this.list / this.step);
    this.pagesOptions = this.pagesArray;
  }
  ngOnChanges(changes: SimpleChanges): void {
    const { step, list, page } = changes;

    if (page && page.currentValue !== page.previousValue) {
      this.page = page.currentValue;
    }
    if (step && step.currentValue !== step.previousValue) {
      this.pages = Math.ceil(this.list / step.currentValue);
      this.pagesOptions = this.pagesArray;
    }
    if (list && list?.currentValue !== list?.previousValue) {
      this.pages = Math.ceil(list.currentValue / this.step);
      this.pagesOptions = this.pagesArray;
    }
  }

  public handleSelectPage(target: any): void {
    this.onChange.emit(Number(target.value));
  }
  public handleOnClick(page: number): void {
    this.onChange.emit(page);
  }
}
