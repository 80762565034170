<nav class="nav no-print">

  <section click-away (clickAway)="closeDrawer()">
    <app-icon class="hamburger-menu" src="/assets/icons/menu.svg" (click)="toggleDrawer()" color="#fff">
    </app-icon>
    <section *ngIf="isDrawerOpen" class="nav-drawer-root" data-source="root">
      <app-admin-menu></app-admin-menu>
    </section>
  </section>

  <section class="links">
    <ng-container *ngFor="let link of adminConfigurations.fastLinks">
      <span class="links-item" routerLink="/{{link.link}}">{{link.text}}</span>
    </ng-container>
  </section>

  <h2 routerLink="/">Site Manager</h2>

  <!-- <section *ngIf="employee$ | async as employee" class='employee dropdown' [attr.data-open]="avatarDropDownOpen">

    <section click-away class="profile" (click)="toggleEmployee()" (clickAway)="closeEmployee()">
      <app-icon class="avatar" *ngIf="!employee.imageUrl" src="/assets/icons/person.svg"
        color="var(--text-darker-color)">
      </app-icon>
      <img class="avatar" *ngIf="employee.imageUrl" [src]="employee.imageUrl" width="16" height="16" />
      <span class="h6-semibold">{{employee.displayName || employee.username}}</span>
    </section>

    <section *ngIf="avatarDropDownOpen" class='profile-body paper'>
      <div class="item paragraph-2-semibold" (click)="employeeLogout()">
        <app-icon src="/assets/icons/log-out.svg" color="var(--text-color)" fontSize="1.1em">
        </app-icon>
        <span>Изход</span>
      </div>
    </section>
  </section> -->
</nav>
