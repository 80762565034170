import { Component, Input } from '@angular/core';
import { ContextService } from '@context';
import { environment } from '@env';
import { CartItem } from '@models/Cart.model';
import { ImageURLItem } from '@models/Image.model';
import { ProductItem } from '@models/Product.model';

@Component({
  selector: 'app-product-card-mini',
  templateUrl: './product-card-mini.component.html',
  styleUrls: ['./product-card-mini.component.scss']
})
export class ProductCardMiniComponent {
  @Input() data: CartItem | null = null;
  @Input() type: 'small' | 'normal' = 'normal';
  @Input() showCategory: boolean = false;

  public get cartItem(): CartItem | null {
    if (!this.data) return null
    return this.data
  }
  public get price(): string {
    return (Number(this.data?.item.price) * Number(this.data?.count)).toFixed(2);
  }
  public get count(): number {
    return this.data?.count || 0;
  }
  public get getProductImage(): ImageURLItem {
    if (!this.cartItem) return { png: { ['250']: '' }, webp: { ['250']: '' } }

    const { storage } = environment;
    return {
      png: {
        '250': `${storage}${this.context.formatForImageName(this.cartItem.item.id.toString())}_250x250.png?alt=media&token=${this.cartItem.item.imageToken}`,
      },
      webp: {
        '250': `${storage}${this.context.formatForImageName(this.cartItem.item.id.toString())}_250x250.webp?alt=media&token=${this.cartItem.item.imageToken}`,
      }
    }
  }

  constructor(private context: ContextService) { }

  public handleRemoveItem(item: ProductItem): void {
    this.context.removeFromCart(item);
  }

  public handleAddItem(item: ProductItem): void {
    this.context.addToCart(item);
  }
  public handleTotalRemove(item: ProductItem, count: number): void {
    this.context.removeFromCart(item, count);
  }
}
