import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PaginateArrayPipe } from '@core/pipes/paginate-array.pipe';
import { AccordionBodyComponent } from './accordion/accordion-body/accordion-body.component';
import { AccordionHeadComponent } from './accordion/accordion-head/accordion-head.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AddRemoveListComponent } from './add-remove-list/add-remove-list.component';
import { TableAddRemoveComponent } from './add-remove-list/table/table.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { FormViewComponent } from './form-view/form-view.component';
import { IconComponent } from './icon/icon.component';
import { ListCardComponent } from './list-card/list-card.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProductCardMiniComponent } from './product-card-mini/product-card-mini.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

const components: any = [
  PaginateArrayPipe,
  IconComponent,
  SnackBarComponent,
  ProductCardMiniComponent,
  FormViewComponent,
  NavBarComponent,
  ListCardComponent,
  ConfirmDialogComponent,
  AccordionBodyComponent,
  AccordionHeadComponent,
  AccordionComponent,
  DialogComponent,
  PaginationComponent,
  TableAddRemoveComponent,
  AddRemoveListComponent,
];
@NgModule({
  declarations: [...components,],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [...components, CommonModule],
})
export class ComponentsModule { }

