<ng-container *ngIf="pages > 1">
  <select *ngIf="showPageDropdown" class="root-pages | outline" (change)="handleSelectPage($event.target)">
    <ng-container *ngFor="let option of pagesOptions">
      <option [value]="option" [selected]="option === page">{{ option }}</option>
    </ng-container>
  </select>

  <span *ngIf="showLabel" class="root-information">{{ page }}/{{ pages }}</span>

  <ng-container *ngIf="showNavigation">
    <button aria-label="prev page" class="page prev" (click)="handleOnClick(page - 1)" [disabled]="page === 1">
      <app-icon src="/assets/icons/chevron-left.svg"></app-icon>
    </button>
    <button aria-label="next page" class="page next" (click)="handleOnClick(page + 1)" [disabled]="page === pages">
      <app-icon src="/assets/icons/chevron-right.svg"></app-icon>
    </button>
  </ng-container>

  <section class="root-results">
    <span>({{ step * page > list ? list : step * page }} / {{ list }})</span>
  </section>
</ng-container>
