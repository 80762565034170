import { AfterViewChecked, Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[draggable]',
})
export class DragDropDirective implements AfterViewChecked, OnChanges {
  private draggableList: ElementRef[] | null = null;

  @Input('dragType') type: 'reorder' | 'swap' = 'reorder';
  @Input('dragList') list: any;
  @Input('dragDisabled') disabled: boolean = false;
  @Output('dragAction') dragAction = new EventEmitter<any>();

  @HostListener('dragstart', ['$event']) onDragStart(event: any): void {
    if (this.disabled) return

    const elRef = this.targetRef(event);
    const position = elRef.dataset.dragPosition;
    event.dataTransfer.setData('prevPosition', position);
  }
  @HostListener('dragover', ['$event']) onDragOver(event: any): void {
    if (this.disabled) return

    event.preventDefault();
    const elRef = this.targetRef(event);
    elRef.dataset.dragOver = true;
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any): void {
    if (this.disabled) return

    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any): void {
    if (this.disabled) return

    const elRef = this.targetRef(event);
    elRef.dataset.dragOver = false;
  }
  @HostListener('drop', ['$event']) onDrop(event: any): void {
    if (this.disabled) return

    const elRef = this.targetRef(event);
    elRef.dataset.dragOver = false;

    const position = elRef.dataset.dragPosition;
    let prevElementIndex = Number(event.dataTransfer.getData('prevPosition'));
    let nextElementIndex = Number(position);

    this.dragAction.emit({
      list: this.sort(this.list, prevElementIndex, nextElementIndex),
      event
    })
  }

  constructor(private el: ElementRef) { }

  ngAfterViewChecked(): void {
    if (this.disabled) return

    this.draggableList = this.el.nativeElement.querySelectorAll('[data-drag]');
    if (!this.draggableList) return
    [...this.draggableList].forEach((node: any, index) => {
      node.setAttribute('draggable', 'true');
      node.setAttribute('data-drag-position', index);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.draggableList = this.el.nativeElement.querySelectorAll('[data-drag]');
      if (!this.draggableList) return
      [...this.draggableList].forEach((node: any) => {
        node.removeAttribute('draggable');
        node.removeAttribute('data-drag-position');
      })
    }
  }

  private targetRef(event: any): any {
    return event.target.dataset.dragPosition ?
      event.target
      :
      event.target.closest('[draggable]');
  }

  private sort(array: Array<any>, prevIndex: number, nextIndex: number): Array<any> {
    if ((prevIndex === nextIndex) || isNaN(prevIndex) || isNaN(nextIndex)) return array

    const clonedList = array.slice();

    if (this.type === 'swap') {
      clonedList.splice(nextIndex, 1, array[prevIndex]);
      clonedList.splice(prevIndex, 1, array[nextIndex]);
      return clonedList
    }

    if (this.type === 'reorder') {
      const isBackDragging = prevIndex > nextIndex;

      if (!isBackDragging) nextIndex++;
      clonedList.splice(nextIndex, 0, array[prevIndex]);
      if (isBackDragging) prevIndex++;
      clonedList.splice(prevIndex, 1);

      return clonedList
    }

    return array
  }
}