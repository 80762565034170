import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input() src: string = '';
  @Input() fontSize: string = '';
  @Input() color: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() source: string = 'root';

  public get currentUrl() {
    return `url(${this.src})`;
  }
  get currentFontSize() {
    return this.fontSize;
  }
  public get currentColor() {
    if (this.color === 'accent') return `var(--accent-color)`;
    return this.color;
  }
  public get currentWidth() {
    return this.width;
  }
  public get currentHeight() {
    return this.height
  }
}
