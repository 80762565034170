import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ContextService } from '@context';
import { environment } from '@env';
import { AdminConfigItem, ShortcutLink } from '@models/admin-config-item';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilitiesConfigService } from './utilities-config.service';

@Injectable({ providedIn: 'root' })

export class AdminConfigurationService implements OnDestroy {
  private subscription: Subscription | null = null;
  private readonly configurationDbRef: AngularFirestoreDocument<AdminConfigItem> = this.db.collection<AdminConfigItem>('application').doc('admin-panel');
  private initialConfigurations: AdminConfigItem = environment.DEFAULT_ADMIN_CONFIGURATION;
  public readonly state$: BehaviorSubject<AdminConfigItem> = new BehaviorSubject<AdminConfigItem>(this.initialConfigurations);
  public isLoading: boolean = false;

  //STATE
  public get configurations(): AdminConfigItem {
    return this.state$.getValue();
  }
  private setState = (newState: Partial<any>): void => {
    const updatedState = { ...this.configurations, ...newState };
    this.state$.next(updatedState);
  }

  private async resetAppConfigurations() {
    await this.configurationDbRef.update({ ...this.initialConfigurations });
  }
  private async createDbRef() {
    this.configurationDbRef.set({});
  }

  constructor(
    private db: AngularFirestore,
    private context: ContextService,
    private utilities: UtilitiesConfigService,
  ) {
    this.subscription = this.configurationDbRef
      .valueChanges()
      .subscribe((config) => {
        if (!config) return
        this.setState(config);
        if (!Object.keys(config).length) this.resetAppConfigurations();
      })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  //FAST LINK
  public async addFastLink(link: ShortcutLink): Promise<void> {
    if (!link || this.configurations.fastLinks?.find(l => l.link === link.link)) return
    try {
      this.isLoading = true;
      const payload = [...this.configurations.fastLinks || [], link];
      const response = await this.configurationDbRef.update({ fastLinks: payload });
      this.context.openSuccessSnackBar('Успешно записан.');
      this.isLoading = false;
      return response
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.createDbRef();
      this.isLoading = false;
    }
  }
  public async removeFastLink(link: ShortcutLink): Promise<void> {
    if (!link || !this.configurations.fastLinks) return
    try {
      this.isLoading = true;
      const payload = this.configurations.fastLinks.filter(l => l.link !== link.link);
      const response = await this.configurationDbRef.update({ fastLinks: payload });
      this.context.openSuccessSnackBar('Успешно премахнат.');
      this.isLoading = false;
      return response
    } catch (error) {
      console.error(error);
      this.context.openFailureSnackBar('Нещо се обърка опитай пак или се свържи с администратор.');
      this.createDbRef();
      this.isLoading = false;
    }
  }
}

