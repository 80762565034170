import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ContextService } from '@context';
@Injectable()

export class AdminService {
  public _loading: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    private context: ContextService,
    private router: Router
    ) { }

  public async login(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.context.openSuccessSnackBar('Успешен вход');
      return this.router.navigate(['/admin']);
    } catch (error) {
      return this.context.openFailureSnackBar('Неуспешен вход');
    }
  }
  public async logout() {
    await this.afAuth.signOut();
    this.context.openSuccessSnackBar('Успешен изход');
    return this.router.navigate(['/login']);
  }
}
