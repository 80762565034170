import { Pipe, PipeTransform } from '@angular/core';
import { OrderOrigin, OrderType } from '@models/OrderItem.model';

type OrderOriginDictionary = {
  [key in OrderOrigin | 'all']: string
}

const ORDERS_STATUSES_DICTIONARY: OrderOriginDictionary = {
  'site': 'Уеб Страница',
  'glovo': 'Глово',
  'phone': 'Телефон',
  'shop': 'Място',
  '#': '#',
  'all': 'Всички',
}
@Pipe({
  name: 'translateOrderOrigin'
})
export class TranslateOrderOriginPipe implements PipeTransform {
  transform(value: string): unknown {
    return (value in ORDERS_STATUSES_DICTIONARY) ?
      ORDERS_STATUSES_DICTIONARY[value as OrderOrigin]
      :
      value
  }
}
