<section class="root-origin-list">
  <app-table-add-remove
    [data]="originList"
    [listSelected]="originListSelected"
    (select)="handleOnSelectFromOriginList($event)"
  >
    <ng-template *ngIf="originSearch" #search>
      <ng-container *ngTemplateOutlet="originSearch"></ng-container>
    </ng-template>
  </app-table-add-remove>
</section>

<section class="root-actions-list">
  <button
    class="outlined-button"
    data-color="primary"
    (click)="handleAddToDefaultList()"
  >
    ▶ ({{ originListSelected.length }})
  </button>
  <button
    class="outlined-button"
    data-color="accent"
    (click)="handleRemoveFromDefaultList()"
  >
    ◀ ({{ defaultListSelected.length }})
  </button>
</section>

<section class="root-new-list">
  <app-table-add-remove
    [data]="defaultList"
    [listSelected]="defaultListSelected"
    (select)="handleOnSelectFromDefaultList($event)"
  >
    <ng-template *ngIf="defaultSearch" #search>
      <ng-container *ngTemplateOutlet="defaultSearch"></ng-container>
    </ng-template>
  </app-table-add-remove>
</section>

<section class="root-actions">
  <button class="contained-button" (click)="handleClickSaveButton()">
    Готово
  </button>
</section>
