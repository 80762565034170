import { Pipe, PipeTransform } from '@angular/core';
import { OrderPayments } from '@models/OrderItem.model';

type OrderPaymentDictionary = {
  [key in OrderPayments | 'total']: string
}

const ORDERS_STATUSES_DICTIONARY: OrderPaymentDictionary = {
  'card': 'С карта',
  'cash': 'В брой',
  'delivery': 'Доставка',
  'mixed': 'Смесено',
  'total': 'Всичко',
}

@Pipe({
  name: 'translateOrderPayment'
})
export class TranslateOrderPaymentPipe implements PipeTransform {
  transform(value: string): unknown {
    return (value in ORDERS_STATUSES_DICTIONARY) ?
      ORDERS_STATUSES_DICTIONARY[value as OrderPayments]
      :
      value
  }
}
