import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  public isPrepared: boolean = false;
  @Input() loading: boolean = false;
  @Input() color: string = 'primary';
  @Input() question: string = 'Are you sure?';
  @Input() label: string = 'Confirm';
  @Output() confirm = new EventEmitter<boolean>();
  @HostListener('document:keydown.escape') keyPressEscape(): void {
    if (!this.isPrepared) return
    this.cancel();
  }
  @HostListener('document:keydown.enter') keyPressEnter(): void {
    if (!this.isPrepared) return
    this.action();
  }

  public prepare = (): void => {
    this.isPrepared = true;
  }
  public cancel = (): void => {
    this.isPrepared = false;
  }
  public action = (): void => {
    this.confirm.emit(true);
    this.isPrepared = false;
  }
}
