import { Injectable } from '@angular/core';

@Injectable()

export class NotificationService {

  constructor() { }

  public get isDefault(): boolean {
    if (typeof window === 'undefined') return false
    return (Notification.permission === 'default')
  }

  public initializationNotification(): void {
    if (typeof window === 'undefined' || !("Notification" in window) || !(Notification.permission === 'default')) {
      return
    }
    Notification.requestPermission();
  }

  public pushNotification(text: string, options?: any): void {
    if (Notification.permission === 'denied') {
      return
    }

    const data = {
      id: new Date().getTime(),
      body: 'Notification',
      timestamp: Math.floor(Date.now()),
      ...options
    }
    new Notification(text, data);
  }
}