import { Component, DoCheck, HostBinding, ViewEncapsulation } from '@angular/core';
import { AccordionService } from '../accordion.service';

@Component({
  selector: 'accordion-body',
  templateUrl: './accordion-body.component.html',
  styleUrls: ['./accordion-body.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccordionBodyComponent implements DoCheck {
  @HostBinding('attr.data-expanded') expanded: boolean = false;

  constructor(public accService: AccordionService) { }

  ngDoCheck(): void {
    this.expanded = this.accService.open
  }
}
